import "./App.scss"
import Home from "./Page/Home"

function App() {
    return (
        <Home />
    )
}

export default App;
