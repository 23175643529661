import founderLogo from "../Assets/Images/logo.svg"
function Header(){
    return(
        <nav className="navbar navbar-light">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src={founderLogo} alt="founder expert logo" />
                </a>
                {/* <form className="d-flex">
                    <PrimaryButton>Test Button</PrimaryButton>
                </form> */}
            </div>
        </nav>
    )
}
export default Header