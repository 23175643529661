import ABoutUsImg from "../../Assets/Images/about/about.png"
import Mission from "../../Assets/Images/about/target.png"
import Vision from "../../Assets/Images/about/binocular.png"
import ScrollAnimation from "react-animate-on-scroll";

function AboutUs(){
    return(
        <div className="about-us-wrapper">
            <div className="container">
                <h5>About</h5>
                <div className="row">
                    <div className="col-md-6">
                        <img src={ABoutUsImg} className="aboutUsImage" alt="about us" />
                    </div>
                    <div className="col-md-6">
                        <div className="about-right-content">
                            <div className="anime-bubble"></div>
                            <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOutRight">
                                <h3>We're Not Your Average Agency</h3>
                                <p className="sub-title">We built The Founder Effect from scratch—just like you're doing with your brand. We're here to make sure you don't have to do it alone.</p>
                                <div className="mission-box">
                                    <img src={Mission} className="mission-img" alt="mission" />
                                    <div className="mission-content">
                                        <h6>Mission</h6>
                                        <p>To elevate founders beyond the startup grind and into industry dominance.</p>
                                    </div>
                                </div>
                                <div className="mission-box">
                                    <img src={Vision} className="mission-img" alt="vision" />
                                    <div className="mission-content">
                                        <h6>Vision</h6>
                                        <p>Turning today's startups into tomorrow's unicorns.</p>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutUs