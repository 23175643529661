import Reput from "../../Assets/Images/service/reputation.png"
import Social from "../../Assets/Images/service/social-media.png"
import Brand from "../../Assets/Images/service/branding.png"
import Perform from "../../Assets/Images/service/performance.png"
import Seo from "../../Assets/Images/service/seo.png"
import Marketting from "../../Assets/Images/service/marketting.png"
import BrandingVal from "../../Assets/Images/service/brandingVal.png"
import ScrollAnimation from "react-animate-on-scroll";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Service(){
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        className: "center",
        centerMode: true,
        centerPadding: "60px",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  centerMode: false,
                  dots: false
                }
            },
        ]
    };
    return(
        <div className="service-main-wrapper">        
            <div className="container">
                <h2 className="page-title">Services</h2>
                <ScrollAnimation animateIn="bounceIn" animateOut="bounceOut">
                    <div className="card-wrapper">
                        <Slider {...settings}>
                            <div className="card-item">
                                <img src={Reput} className="service-img" alt="service" />
                                <h3>Reputation Management</h3>
                                <p>Keep the buzz positive while we handle the dirty work of cleaning up any mess.</p>
                            </div>
                            <div className="card-item">
                                <img src={Social} className="service-img" alt="service" />
                                <h3>Social Media Marketing</h3>
                                <p>We don't just post, we spark conversations that matter.</p>
                            </div>
                            <div className="card-item">
                                <img src={Brand} className="service-img" alt="service" />
                                <h3>Personal Branding</h3>
                                <p>Make your name synonymous with success and innovation.</p>
                            </div>
                            <div className="card-item">
                                <img src={Perform} className="service-img" alt="service" />
                                <h3>Performance Marketing</h3>
                                <p>Ads that don't suck your budget dry. ROI all the way.</p>
                            </div>
                            <div className="card-item">
                                <img src={Seo} className="service-img" alt="service" />
                                <h3>SEO & Website Development</h3>
                                <p>Because a stunning website is just the beginning—let’s make it a conversion machine.</p>
                            </div>
                            <div className="card-item">
                                <img src={BrandingVal} className="service-img" alt="service" />
                                <h3>Brand Building</h3>
                                <p>We’ll build your brand from the ground up—brick by brick, story by story. From Brand Identity to Brand Strategy, we craft identities that are impossible to forget.</p>
                            </div>
                            <div className="card-item">
                                <img src={Marketting} className="service-img" alt="service" />
                                <h3>Brand Marketing</h3>
                                <p>It’s not just about selling a product; it’s about telling a story. We’ll market your brand in ways that connect, captivate, and convert—turning casual viewers into die-hard fans.</p>
                            </div>
                        </Slider>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    )
}
export default Service