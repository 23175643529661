import Intro from "../../Assets/Images/intro.png"
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function DeliveryProcess() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                }
              },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: false
                }
            },
        ]
    };
    return (
        <>
            <div className="about-us-wrapper delivery-process-wrap">
                <div className="container">
                    <h5>Delivery Process Methodology</h5>
                    <h3 className="page-sec-title">The Secret Sauce to Scaling Founders</h3>
                    <img src={Intro} alt="intro banner" loading="lazy" className="intro-banner" />
                    <ScrollAnimation animateIn="bounceIn" animateOut="bounceOut">
                        <div className="left-content">
                            {/* <img src={SnowImg} alt="snow falling gif" loading="lazy" className="snow-rotate-img" /> */}
                            <div className="left-boxes">
                                <h6>Discovery</h6>
                                <p>We kick things off by getting into the founder mindset (yes, it’s different).</p>
                            </div>
                            <div className="left-boxes">
                                <h6>Strategy Development</h6>
                                <p>Blueprints built around your goals—think: part science, part magic.</p>
                            </div>
                            <div className="left-boxes">
                                <h6>Execution</h6>
                                <p>We don’t just launch campaigns; we create waves.</p>
                            </div>
                            <div className="left-boxes">
                                <h6>Optimization</h6>
                                <p>Let’s tweak and twist until your metrics are off the charts.</p>
                            </div>
                            <div className="left-boxes">
                                <h6>Growth & Scale</h6>
                                <p>Now that you’re thriving, it’s time to turn the volume up to 11.</p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
            <div className="about-us-wrapper delivery-process-wrap ai-service-wrap">
                <div className="container">
                    <h5>AI Services</h5>
                    <h3 className="page-sec-title">Innovative Solutions for a Connected Future: AI, Cloud, and Beyond</h3>
                    <ScrollAnimation animateIn="bounceIn" animateOut="bounceOut">
                        <div className="process-box-container">
                            <Slider {...settings}>
                                <div className="process-box">
                                    <div className="process-count">01.</div>
                                    <div className="process-content">
                                        <h5>Custom Software Development</h5>
                                        <p>Got a unique idea? We’ll build the code to make it real. No off-the-shelf solutions here—just custom-crafted software, built to match your vision perfectly.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">02.</div>
                                    <div className="process-content">
                                        <h5>Cloud Computing Services</h5>
                                        <p>Your data, wherever you need it. We help you float above the competition with secure, scalable cloud solutions that keep your business light and fast.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">03.</div>
                                    <div className="process-content">
                                        <h5>Data Analytics and Business Intelligence</h5>
                                        <p>Data isn’t just numbers—it’s a roadmap. Let’s turn all that info into insights that fuel your next big move.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">04.</div>
                                    <div className="process-content">
                                        <h5>Chatbot Development</h5>
                                        <p>We build chatbots that don’t just talk—they understand. Delight customers, handle queries, and make your brand’s voice heard 24/7.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">05.</div>
                                    <div className="process-content">
                                        <h5>Cloud Migration Services</h5>
                                        <p>Time to ditch the hardware and soar into the cloud. We’ll handle the heavy lifting, so your migration is as smooth as your future operations.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">06.</div>
                                    <div className="process-content">
                                        <h5>Cybersecurity Services</h5>
                                        <p>Hackers, beware. We’ll keep your data locked down tight with bulletproof cybersecurity solutions that don’t cut corners.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">07.</div>
                                    <div className="process-content">
                                        <h5>Mobile App Development</h5>
                                        <p>From brilliant idea to app-store-ready, we craft sleek, user-friendly mobile apps that make people wonder, ‘How did I ever live without this?’</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">08.</div>
                                    <div className="process-content">
                                        <h5>Artificial Intelligence Development</h5>
                                        <p>AI that actually makes your life easier. Whether it’s automation or advanced decision-making, we bring the future of intelligence to your fingertips.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">09.</div>
                                    <div className="process-content">
                                        <h5>Internet of Things (IoT) Development</h5>
                                        <p>Your devices? Let’s make them talk to each other. From smart homes to smart factories, we turn ‘connected’ into ‘can’t-live-without-it.’</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">10.</div>
                                    <div className="process-content">
                                        <h5>Natural Language Processing (NLP)</h5>
                                        <p>From chatbots to sentiment analysis, we give machines the power to understand human language—because why should humans have all the fun?</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">11.</div>
                                    <div className="process-content">
                                        <h5>IT Consulting and Strategy</h5>
                                        <p>IT challenges? Consider them solved. We provide strategies that align tech with your business goals, making sure you’re always a step ahead.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">12.</div>
                                    <div className="process-content">
                                        <h5>IT Infrastructure Management</h5>
                                        <p>Keeping your IT humming along, 24/7. We handle the backend chaos, so you can focus on what you do best.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">13.</div>
                                    <div className="process-content">
                                        <h5>Web Development (Full Stack)</h5>
                                        <p>Full-stack? More like full-service. From front-end flair to back-end brilliance, we build sites that don’t just look good—they work flawlessly.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">14.</div>
                                    <div className="process-content">
                                        <h5>Machine Learning Solutions</h5>
                                        <p>Your data’s smarter than you think. With our machine learning solutions, we turn it into patterns, predictions, and business breakthroughs.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">15.</div>
                                    <div className="process-content">
                                        <h5>Robotic Process Automation (RPA)</h5>
                                        <p>Say goodbye to repetitive tasks. We’ll automate the mundane so your team can focus on the big stuff—and yes, robots are involved.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">16.</div>
                                    <div className="process-content">
                                        <h5>Data Science Services</h5>
                                        <p>Your data has a lot to say, and we’re the translators. Our data science experts turn raw numbers into actionable insights.</p>
                                    </div>
                                </div>
                                <div className="process-box">
                                    <div className="process-count">17.</div>
                                    <div className="process-content">
                                        <h5>UX/UI</h5>
                                        <p>Design that doesn’t just look good but feels good. We craft intuitive, delightful user experiences that keep your customers coming back.</p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </>
    )
}
export default DeliveryProcess