import Header from "../Header"

function Layout({children}){
    return(
        <div className="main-home-layout">
            <Header />
            {children}
        </div>
    )
}
export default Layout