import BoldMove from "../../Assets/Images/special/moving.png"
import Result from "../../Assets/Images/special/results.png"
import Custom from "../../Assets/Images/special/customize.png"
import ScrollAnimation from "react-animate-on-scroll";

function WeSpecial(){
    return(
        <div className="about-us-wrapper we-special-wrapper">
            <div className="container">
                <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutLeft">
                    <h5>Why Are We Special?</h5>
                    <h3>We Get Founders Because We Are Founders</h3>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOutRight">    
                    <div className="special-box-container">
                        <div className="special-box">
                            <div className="special-icon-wrap">
                                <img src={BoldMove} alt="custom setting icon" loading="lazy" className="special-b-icon" />
                            </div>
                            <h6>Bold Moves</h6>
                            <p>Our playbook is all about bold, entrepreneurial action. No cookie-cutter solutions here.</p>
                        </div>
                        <div className="special-box">
                            <div className="special-icon-wrap">
                                <img src={Result} alt="custom setting icon" loading="lazy" className="special-b-icon" />
                            </div>
                            <h6>Results That Talk</h6>
                            <p>Our success stories? They read like founder fairy tales—with real data to back ‘em up.</p>
                        </div>
                        <div className="special-box">
                            <div className="special-icon-wrap">
                                <img src={Custom} alt="custom setting icon" loading="lazy" className="special-b-icon" />
                            </div>
                            <h6>Custom, Just for You</h6>
                            <p>No pre-made templates. Every strategy is handcrafted to fit your unique founder journey.</p>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    )
}
export default WeSpecial