import Layout from "../Components/Layout/Layout";
import AboutUs from "../Components/Section/AboutUs";
import DeliveryProcess from "../Components/Section/DeliveryProcess";
import HomeBanner from "../Components/Section/HomeBanner"
import Service from "../Components/Section/Services";
import WeSpecial from "../Components/Section/WeSpecial";
import Instagram from "../Assets/Images/channels/instagram.png"
import Linkedin from "../Assets/Images/channels/linkedin.png"


function Home(){
    return(
        <div className="main-home-page-wrape">
            <Layout>
                <HomeBanner />
                <Service />
                <WeSpecial />
                <AboutUs />
                <DeliveryProcess />
                <div className="home-footer-wrap">
                    {/* <div className="copyright-text">© {new Date().getFullYear()} Copyright: MDBootstrap.com</div> */}
                    <a className="channels-icons" href="https://www.instagram.com/the.founder.effect.in/" target="_blank">
                        <img src={Instagram} alt="instagram" />
                    </a>
                    <a className="channels-icons" href="https://www.linkedin.com/company/the-founder-effect/?viewAsMember=true" target="_blank">
                        <img src={Linkedin} alt="linkedin" />
                    </a>
                </div>
            </Layout>
        </div>
    )
}
export default Home