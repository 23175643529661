import BannerImg from "../../Assets/Images/banner.png"

function HomeBanner(){
    return(
        <div className="home-banner-wrapper">        
                <div className="container">
                    <h1>Founders, Let’s Break Some Rules<br /> (The Good Kind)</h1>
                    <p>Bold strategies to supercharge your startup and unlock growth.</p>
                    {/* <p className="second-para">Ready to Elevate?</p> */}
                    <a href="mailTo:grow@thefoundereffect.in" className="btn btn-primary">Let's Grow Together!</a>
                    <div className="animation-ball"></div>
                    <img src={BannerImg} className="banner-img" alt="founder banner-image" />
                </div>
            </div>
    )
}
export default HomeBanner